const attributeNameId = "id";
const attributeNameBenefitId = "benefit-id";
const attributeNameFeature = "feature";
const attributeNameTeam = "team";
const attributeNameVariationId = "variation-id";
const attributeNameTargetContextDreson = "target-context-dreson";
const attributeNameCurrentContextDreson = "current-context-dreson";
const attributeNameSendTracking = "send-tracking";
const attributeNameSendEvents = "send-events";
const attributeNameRemoveExpired = "remove-expired";
import { moduleLogger } from "./util";

const log = moduleLogger.scope("tag");

/*                                                                                                   */
/*                                                                              */
const MAX_CLEANUP_PERIOD_IN_MILLIS = 20 * 24 * 60 * 60 * 1000;

export class BenefitTagElement extends HTMLElement {
	static observedAttributes = [
		attributeNameId,
		attributeNameBenefitId,
		attributeNameFeature,
		attributeNameTeam,
		attributeNameVariationId,
		attributeNameTargetContextDreson,
		attributeNameCurrentContextDreson,
		attributeNameSendTracking,
		attributeNameSendEvents,
		attributeNameRemoveExpired,
	];
	private _isConnected = false;

	override get isConnected() {
		return this._isConnected;
	}

	override set isConnected(value: boolean) {
		this._isConnected = value;
	}

	constructor() {
		super(); /*                                     */
		this.isConnected = false;
	}

	async attributeChangedCallback(name: string, oldValue: string, newValue: string) {
		log.info("Attribute update: ", name, oldValue, newValue, this.isConnected);
		if (this.isConnected) {
			await this.callBackend();
		}
	}

	async connectedCallback() {
		this.isConnected = true;
		await this.callBackend();
	}

	async callBackend() {
		const id = this.getAttribute(attributeNameId);
		const urlSearchParams = this.getSearchParams();

		log.info(`Calling tag for ${id} with ${JSON.stringify(urlSearchParams)}`);

		let tries = 0;
		let success = false;
		const status_code = [];

		while (tries++ < 3 && !success) {
			let response: Response;
			try {
				response = await fetch(`/benefit-tag/tag?${urlSearchParams}`);
			} catch (error) {
				log.warn(`tag request returns an error! ${error}`);

				if (error instanceof DOMException) {
					status_code.push(error.name);
				} else {
					status_code.push(error);
				}
				continue;
			}

			status_code.push(response.status);
			success = response.ok;

			if (success) {
				/*                          */
				response.text().then((text) => {
					this.innerHTML = text;
					initTag(this);
				});
				break;
			}
			log.warn(`tag endpoint returns a non ok response! ${response.status}`);
		}

		if (!success) {
			window.AS?.RUM?.sendCustomRequest("ft9-benefit-tag-not-ok", {
				urlSearchParams: urlSearchParams.toString(),
				status_code: status_code,
				tries: tries - 1,
			});
		}
	}

	private getSearchParams() {
		const urlSearchParams = new URLSearchParams({});

		function addToSearchParam(parmName: string, value: string | null) {
			if (value != null) {
				urlSearchParams.append(parmName, value);
			}
		}

		addToSearchParam("benefitId", this.getAttribute(attributeNameBenefitId));
		addToSearchParam("feature", this.getAttribute(attributeNameFeature));
		addToSearchParam("team", this.getAttribute(attributeNameTeam));
		addToSearchParam("variationId", this.getAttribute(attributeNameVariationId));
		addToSearchParam("targetContextDreson", this.getAttribute(attributeNameTargetContextDreson));
		addToSearchParam("currentContextDreson", this.getAttribute(attributeNameCurrentContextDreson));
		return urlSearchParams;
	}
}

function initTag(tagElement: BenefitTagElement) {
	if (tagElement.childElementCount === 0) {
		return;
	}

	const element = tagElement.firstElementChild;
	if (tagElement.hasAttribute(attributeNameRemoveExpired) && element && element.hasAttribute("data-expiration-date")) {
		const expirationDate = new Date(element.getAttribute("data-expiration-date") as string);

		const delayInMillis: number = Math.min(Math.max(expirationDate.getTime() - new Date().getTime(), 1), MAX_CLEANUP_PERIOD_IN_MILLIS);
		window.setTimeout(() => {
			element.remove();
		}, delayInMillis);
	}
}

customElements.define("ft9-benefit-tag-v1", BenefitTagElement);
